import { CardCarrinhoProps } from './card-carrinho-props';
import { useStyles } from './card-carrinho-styles';
import { useThemeQueries } from 'views';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import React, { useState } from 'react';
import ContentImage from './components/card-carrinho-imagem/card-carrinho-imagem';
import { BotoesAddESub } from './components/button-add-e-sub/button-add-e-sub';
import { BarcodeIcon, CategoriaIcon, DesfazerIcon, EditarIcon } from 'views/components/icons';
import InfoProduto from './components/info-produto/info-produto';
import { isEmpty } from 'lodash';

import { useCadastros } from 'services/app';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { DefaultCard } from '../components';
import classNames from 'classnames';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { MenuVerticalIcon } from 'views/components/icons/menu-vertical-icon';
import { PromocaoTipoRegraMock } from 'data/mocks/promocao-mock';
import { PromocaoDePorIcon } from 'views/components/icons/promocao-depor-icon';
import { PromocaoAtacadoIcon } from 'views/components/icons/promocao-atacado-icon';
import { PromocaoLeveXPagueY } from 'views/components/icons/promocao-levex-paguey-icon';
import { PromocaoProgressivoIcon } from 'views/components/icons/promocao-progressivo-icon';
import { LaboratorioIcon } from 'views/components/icons/laboratorio-icon';


const CardCarrinho = ({
  model,
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  setProductEdit
}: CardCarrinhoProps) => {

  //AUX
  const classes = useStyles();
  const { theme, xs } = useThemeQueries();
  const { abrirDialogOpcoesProdutoCarrinho } = useCadastros();

  const [verMais, setVerMais] = useState<boolean>(false);

  const valorAcumulado = model.adicionais.reduce(
    (acc, current) => acc
      + (current.vUnCom * (current.qComModificador > 0 ? current.qComModificador : current.qCom))
      - (current.vDesc + current.vDescEmbutido)
      + (current.vOutro + current.vOutroEmbutido)
    , 0);

  const valorProduto = model.vUnComOrig ?? 0;
  const valorPMC = model.med?.vPMC ?? 0;
  const valorPromocao = toDecimal((model.vFinal / model.qCom) ?? 0, 2);

  const valorDe = valorProduto > valorPMC ? valorProduto : valorPMC;
  const valorPorSemPromos = toDecimal((model.vFinal + model.vDesc - model.vOutro) / model.qCom, 2);
  const valorPorLiquido = valorPromocao;


  const nomeECategoria = React.useMemo(() => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ lineHeight: '16px' }}
              >
                <Box style={{ fontWeight: 600 }}>{model.xProd}</Box>
              </Typography>

              <Grid className={classes.tags} flex>
                {model.cEan && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                    <BarcodeIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {model.cEan}
                    </Typography>
                  </Grid>
                )}
                {(model.cProd !== model.cEan && model.cProd) && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                    <BarcodeIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {model.cProd}
                    </Typography>
                  </Grid>
                )}
                {model.med?.medicamento?.tarja !== undefined && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagTarja)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {TarjaMedicamentoMock.find((tarja) => tarja.Key === model.med?.medicamento?.tarja)?.Value ?? ''}
                    </Typography>
                  </Grid>
                )}
                {model.med?.medicamento?.tipoMedicamento !== undefined && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagTipoMedicamento)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {TipoMedicamentoMock.find((tipo) => tipo.Key === model.med?.medicamento?.tipoMedicamento)?.Value ?? ''}
                    </Typography>
                  </Grid>
                )}
                {!isEmpty(model.med?.medicamento?.laboratorioNome) && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagLaboratorio, (model.med?.medicamento?.laboratorioNome ?? '').toUpperCase().indexOf("CIMED") > -1 ? classes.tagLaboratorioDestaque : undefined)}>
                    <LaboratorioIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {model.med?.medicamento?.laboratorioNome}
                    </Typography>
                  </Grid>
                )}
                {model.med?.medicamento?.classeTerapeutica !== undefined && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagClasse)}>
                    <CategoriaIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {model.med?.medicamento?.classeTerapeutica}
                    </Typography>
                  </Grid>
                )}
                {model.med?.medicamento?.composicao !== undefined && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagComposicao)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                      {model.med?.medicamento?.composicao}
                    </Typography>
                  </Grid>
                )}
                {model.vOutro > 0 && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                    <PromocaoDePorIcon tipo="GERAL" />
                    <Typography variant="caption">
                      <i>Acréscimo: </i>
                      <strong>+ R${' '}{toDecimalString(model.vOutro, 2)}</strong>
                    </Typography>
                  </Grid>
                )}
                {((valorDe - valorPorSemPromos) * model.qCom) > 0 && (
                  <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                    <PromocaoDePorIcon tipo="GERAL" />
                    <Typography variant="caption">
                      <i>'De/Por'</i>
                      <strong>- R${' '}{toDecimalString(((valorDe - valorPorSemPromos) * model.qCom), 2)}</strong>
                    </Typography>
                  </Grid>
                )}
                {model.descontos.filter(x => (x.promocao?.tipoPromocaoRegra ?? EnumPromocaoTipoRegra.DePor) !== EnumPromocaoTipoRegra.DePor).map((d) => {
                  let icon = <PromocaoIcon tipo="GERAL" />
                  switch (d.promocao?.tipoPromocaoRegra) {
                    case EnumPromocaoTipoRegra.DePor:
                      icon = <PromocaoDePorIcon tipo="GERAL" />;
                      break;
                    case EnumPromocaoTipoRegra.AtacadoValor:
                      icon = <PromocaoAtacadoIcon tipo="GERAL" />;
                      break;
                    case EnumPromocaoTipoRegra.AtacadoPerc:
                      icon = <PromocaoAtacadoIcon tipo="GERAL" />;
                      break;
                    case EnumPromocaoTipoRegra.LeveMaisPagueMenosQtde:
                      icon = <PromocaoLeveXPagueY tipo="GERAL" />;
                      break;
                    case EnumPromocaoTipoRegra.ProgressivoPerc:
                      icon = <PromocaoProgressivoIcon tipo="GERAL" />;
                      break;
                  }

                  return (
                    <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)
                    } >
                      {icon}
                      <Typography variant="caption">
                        <i>{PromocaoTipoRegraMock.find(x => x.Key === d.promocao?.tipoPromocaoRegra)?.Value ?? ''}{' (' + d.promocao?.promocaoNome + ')'}: </i>
                        <strong>- R${' '}{toDecimalString(d.promocao?.valorDesconto, 2)}</strong>
                      </Typography>
                    </Grid>
                  )
                })
                }
              </Grid>

            </Grid>

            <Grid item xs={12}>
              {!isEmpty(model.infAdic) && (
                <Grid item xs={12} className={classes.containerTexto}>
                  <Typography variant="caption" color="textSecondary" style={{ lineHeight: '10px' }}>
                    <Box style={{ fontWeight: 600 }}>Observação</Box>
                  </Typography>
                  <Typography variant="body2">
                    {model.infAdic.length > 60 && xs && !verMais
                      ? model.infAdic.substring(0, 60) + `...`
                      : model.infAdic}
                    {model.infAdic.length > 60 && xs && (
                      <span
                        className={classes.texto}
                        onClick={() => setVerMais((prev) => !prev)}
                      >
                        {verMais ? 'Ver menos' : 'Ver mais'}
                      </span>
                    )}
                  </Typography>
                </Grid>
              )}


            </Grid>
          </Grid>
        </Grid >
      </>
    );
  }, [model.xProd, model.cEan, model.cProd, model.med?.medicamento?.tarja, model.med?.medicamento?.tipoMedicamento, model.med?.medicamento?.laboratorioNome, model.med?.medicamento?.classeTerapeutica, model.med?.medicamento?.composicao, model.vOutro, model.qCom, model.descontos, model.infAdic, classes.tags, classes.tagProdutos, classes.tagCodigos, classes.tagTarja, classes.tagTipoMedicamento, classes.tagLaboratorio, classes.tagLaboratorioDestaque, classes.tagClasse, classes.tagComposicao, classes.tagDesconto, classes.containerTexto, classes.texto, valorDe, valorPorSemPromos, xs, verMais]);


  const valor = React.useMemo(() => {
    return (
      <Grid className={classes.containerValorUnidade} flex flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typography variant="subtitle1" className={classes.textVTotal}>
          Valor Unitário
        </Typography>
        {(valorDe > 0 && valorDe > valorPorLiquido) && (
          <Typography variant="body1" color='textSecondary' className={classNames(classes.textVUnitario, classes.textVUnitarioPequeno, classes.textVUnitarioDe)}>
            R$ {toDecimalString(valorDe, 2)} {model.uCom}{valorDe === valorPMC ? '*' : ''}
          </Typography>
        )}
        <Typography variant="body1" color="primary" className={classNames(classes.textVUnitario, valorDe > valorPorLiquido ? classes.textVUnitarioPequeno : undefined)}>
          R$ {toDecimalString(valorPorLiquido, 2)} {model.uCom}
        </Typography>
      </Grid>
    );
  }, [classes.containerValorUnidade, classes.textVTotal, classes.textVUnitario, classes.textVUnitarioDe, classes.textVUnitarioPequeno, model.uCom, valorDe, valorPMC, valorPorLiquido]);

  return (
    <>
      <DefaultCard notClickable className={model.ativo ? classes.card : classes.cardinativo}>
        <Grid
          item
          xs={12}
          className={classes.containerInternoCard}
        >
          <Grid className={classes.containerInativar}>
            {model.ativo ? (
              <Button
                onClick={() =>
                  abrirDialogOpcoesProdutoCarrinho(model, inativarProduto)
                }
                rounded
                className={classes.btnInativar}
              >
                <MenuVerticalIcon
                  tipo="BUTTON_PRIMARY"
                  fill={theme.palette.grey[400]}
                  style={{
                    marginRight: '0px'
                  }}
                />
              </Button>
            ) : (
              <Button
                onClick={async () => await reativarProduto(model)}
                rounded
                className={classes.btnRetornar}
              >
                <DesfazerIcon
                  fill={theme.palette.primary.main}
                  tipo="BUTTON_PRIMARY"
                  style={{
                    marginRight: '0px'
                  }}
                />
              </Button>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} container>
              <Grid className={classes.containerInfo}>
                <ContentImage imgUrl={model.imgUrl} xProd={model.xProd} />
              </Grid>
              <Grid item style={{ flex: 1 }}>{nomeECategoria}</Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 4 }}>
              <Divider variant="fullWidth" className={classes.divider} />
            </Grid>

            <Grid item xs={12} className={classes.containerValores}>
              <Grid>{valor}</Grid>
              <Grid>
                {model.subItens.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setProductEdit(model.id)}
                    size="small"
                    rounded
                  >
                    {' '}
                    <EditarIcon
                      tipo="BUTTON_PRIMARY"
                      style={{ width: '22px', height: '22px' }}
                    />{' '}
                    Editar{' '}
                  </Button>
                ) : (
                  <BotoesAddESub
                    model={model}
                    alterarQtdeProduto={alterarQtdeProduto}
                    inativarProduto={inativarProduto}
                  />
                )}
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end'
                }}
              >
                <Typography variant="subtitle1" className={classes.textVTotal}>
                  Valor total
                </Typography>
                <Typography variant="subtitle1" color='primary' className={classes.textVProd}>
                  R${' '}
                  {toDecimalString(
                    (model.tpProduto === EnumTpProduto.Combo
                      ? 0
                      : model.vFinal) + valorAcumulado,
                    2
                  )}
                </Typography>
              </Grid>
            </Grid>

            {model.tpProduto !== EnumTpProduto.Combo &&
              model.prodSubItem.length > 0 &&
              model.prodSubItem.filter((p) => p.qCom > 0).length > 0 && (
                <Grid item xs={12}>
                  <p className={classes.pAdicional}>Inclusos</p>
                </Grid>
              )}
            {model.tpProduto !== EnumTpProduto.Combo &&
              model.prodSubItem.length > 0 &&
              model.prodSubItem.map((p) => (
                <Grid item xs={12}>
                  <InfoProduto
                    key={p.id}
                    incluso
                    produto={{
                      ...p,
                      qCom:
                        p.qCom > p.infoSubItem!.qPadrao
                          ? p.infoSubItem!.qPadrao
                          : p.qCom
                    }}
                    qtdPai={model.qCom}
                    qtdTotal={model.prodSubItem.filter((p) => p.qCom > 0)}
                  />
                </Grid>
              ))}

            {model.adicionais.length > 0 && (
              <>
                <Grid item xs={12}>
                  <p className={classes.pAdicional}>
                    {model.tpProduto !== EnumTpProduto.Combo
                      ? 'Adicionais'
                      : 'Composição'}
                  </p>
                </Grid>
                {model.adicionais
                  .filter((p) => p.qCom > 0)
                  .map((p) => (
                    <Grid item xs={12}>
                      <InfoProduto
                        key={p.id}
                        produto={p}
                        qtdPai={
                          model.qComModificador > 0
                            ? model.qComModificador
                            : model.qCom
                        }
                        qtdTotal={model.adicionais.filter(
                          (p) => p.qCom > 0 && p.modificadorId
                        )}
                      />
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
export default React.memo(CardCarrinho);
