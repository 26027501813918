import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback } from 'react';
import { useMovAtual } from './mov-atual';
import { useToastSaurus } from './toast-saurus';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { EnumTpDescontoAcrescimo } from 'model/enums/enum-tp-desconto-acrescimo';
import { AdicionarDescontoAcrescimoFormModel } from 'model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model';
import { toDecimal } from 'utils/to-decimal';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from './events/event-tools';
import { useDescontoAcrescimo } from './desconto-acrescimo';
import { EnumAcrescimoDesconto } from 'model/enums/enum-acrescimo-desconto';

export const useCarrinho = () => {
  // HOOKS
  const { getMov, alterarProduto } = useMovAtual();
  const { showToast } = useToastSaurus();
  const { callEvent } = useEventTools();
  const { adicionarAcrescimoDescontoItem } = useDescontoAcrescimo();

  const alterarProdutoCarrinho = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      naoAlteraTaxa: boolean = false,
      limparDescontos: boolean = true,
    ) => {
      try {
        await alterarProduto(produto, naoAlteraTaxa, limparDescontos);
      } catch (e: any) {
        showToast('error', e.message);
        throw e
      }
    },
    [alterarProduto, showToast]
  );

  const inativarSubItens = useCallback(
    async (produto: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {
      if (produto.prodSubItem.length > 0) {
        const movimentacao = getMov();

        if (!movimentacao) {
          return;
        }

        const prodNaMov = movimentacao.produtos.filter(
          (p) => p.ativo && p.idGroup === produto.id && p.tpProduto !== EnumTpProduto.ProdutoComSubItem && p.tpProduto !== EnumTpProduto.Combo
        );

        if (prodNaMov.length > 0) {
          for (let i of prodNaMov) {
            await inativarSubItens(i, naoAlteraTaxa);
          }
        }
      }

      const prodDesativado = Object.assign({}, produto);
      prodDesativado.ativo = false;
      try {
        await alterarProdutoCarrinho(prodDesativado, naoAlteraTaxa);
      } catch (err: any) {
        showToast('error', err.message)
      }
    },
    [alterarProdutoCarrinho, getMov, showToast]
  );

  const ativarSubItens = useCallback(
    async (produto: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {
      if (produto.prodSubItem.length > 0) {
        const movimentacao = getMov();

        if (!movimentacao) {
          return;
        }

        const prodNaMov = movimentacao.produtos.filter(
          (p) => p.ativo === false && p.idGroup === produto.id && p.tpProduto !== EnumTpProduto.ProdutoComSubItem && p.tpProduto !== EnumTpProduto.Combo
        );

        if (prodNaMov.length > 0) {
          for (let i of prodNaMov) {
            await ativarSubItens(i, naoAlteraTaxa);
          }
        }
      }

      const prodDesativado = Object.assign({}, produto);
      prodDesativado.ativo = true;
      try {
        await alterarProdutoCarrinho(prodDesativado, naoAlteraTaxa);
      } catch (err: any) {
        showToast('error', err.message)
      }
    },
    [alterarProdutoCarrinho, getMov, showToast]
  );

  const inativarProduto = useCallback(
    async (model: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {

      const newState = Object.assign({}, model);
      newState.ativo = false;

      if (model.prodSubItem.length > 0) {
        await inativarSubItens(model, naoAlteraTaxa);
      } else {
        try {
          await alterarProdutoCarrinho(newState, naoAlteraTaxa);
        } catch (err: any) {
          showToast('error', err.message)
        }
      }
    },
    [alterarProdutoCarrinho, inativarSubItens, showToast]
  );

  const reativarProduto = useCallback(
    async (
      model: MovSimplesProdutoModel,
      naoAlteraTaxa: boolean = false,
      limparDescontos: boolean = true,
    ) => {
      const newState = Object.assign({}, model);
      newState.ativo = true;

      if (model.prodSubItem.length > 0) {
        await ativarSubItens(model, naoAlteraTaxa);
      } else {
        try {
          await alterarProdutoCarrinho(newState, naoAlteraTaxa, limparDescontos);
        } catch (err: any) {
          showToast('error', err.message)
        }
      }
    },
    [alterarProdutoCarrinho, ativarSubItens, showToast]
  );

  const alterarQtdSubItens = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      qtde: number,
      fator: 'sub' | 'add',
      qtdAtualDoPai: number,
      naoAlteraTaxa: boolean = false
    ) => {
      const newState = Object.assign({}, produto);
      let quantidadeAnteriorPai = qtdAtualDoPai - 1;
      let quantidadeAnteriorItem = produto.qCom;

      let novaQuantidadeAddItem =
        (qtdAtualDoPai * quantidadeAnteriorItem) / quantidadeAnteriorPai;
      let novaQuantidadeSubItem =
        (qtdAtualDoPai * quantidadeAnteriorItem) / (qtdAtualDoPai + 1);

      const newQuantity =
        fator === 'sub' ? novaQuantidadeSubItem : novaQuantidadeAddItem;

      if (produto.prodSubItem.length > 0) {
        const movimentacao = getMov();

        if (!movimentacao) {
          return;
        }

        const prodNaMov = movimentacao.produtos.filter(
          (p) => p.ativo === true && p.idDoProdutoPaiInfoSubItem === produto.id
        );

        if (prodNaMov.length > 0) {
          for (let i of prodNaMov) {
            await alterarQtdSubItens(
              i,
              qtde,
              fator,
              newQuantity,
              naoAlteraTaxa
            );
          }
        }
      }

      newState.qCom = newQuantity;
      newState.vFinal = newState.vUnCom * newState.qCom;
      newState.vProd = newState.vFinal;

      if (newState.qCom <= 0) return;

      try {
        await alterarProdutoCarrinho(newState, naoAlteraTaxa);
      } catch (err: any) {
        showToast('error', err.message)
      }
    },
    [alterarProdutoCarrinho, getMov, showToast]
  );

  const alterarValorAcresDescQuantidadeProduto = useCallback((model: MovSimplesProdutoModel) => {
    // if (model.vDesc > 0) {
    //   model.vDesc = model.tpAcresDesc === EnumTpDescontoAcrescimo.Porcentagem
    //     ? roundTo(model.qCom * model.vUnCom * (model.vAcresDesc / 100), 2)
    //     : roundTo(model.qCom * model.vAcresDesc, 2)
    //   return
    // }

    // model.vOutro = model.tpAcresDesc === EnumTpDescontoAcrescimo.Porcentagem
    //   ? roundTo((model.qCom * model.vUnCom) * (model.vAcresDesc / 100), 2)
    //   : roundTo(model.qCom * model.vAcresDesc)

  }, [])

  const alterarQtdProduto = useCallback(
    async (
      model: MovSimplesProdutoModel,
      qtde: number,
      fator: 'sub' | 'add',
      naoAlteraTaxa: boolean = false
    ) => {
      if (qtde === 0) return;

      if (model.prodSubItem.length > 0) {
        const newState = Object.assign({}, model);
        const newQuantity =
          fator === 'sub' ? newState.qCom - qtde : newState.qCom + qtde;

        newState.qCom = newQuantity;

        await alterarQtdSubItens(
          model,
          qtde,
          fator,
          newQuantity,
          naoAlteraTaxa
        );
      } else {
        const newState = Object.assign({}, model);
        const newQuantity =
          fator === 'sub' ? newState.qCom - qtde : newState.qCom + qtde;

        newState.qCom = newQuantity;

        if (newState.qCom <= 0) return;
        try {
          await alterarProdutoCarrinho(newState, naoAlteraTaxa);
        } catch (err: any) {
          showToast('error', err.message)
        }
      }
    },
    [alterarProdutoCarrinho, alterarQtdSubItens, showToast]
  );

  const adicionarDescontoProduto = useCallback(async (
    produto: MovSimplesProdutoModel,
    model: AdicionarDescontoAcrescimoFormModel,
    fecharDialog: () => void,
    naoAlteraTaxa: boolean = true,
  ) => {
    try {
      const prod = await adicionarAcrescimoDescontoItem(EnumAcrescimoDesconto.DESCONTO, produto, model);

      await alterarProdutoCarrinho(prod, naoAlteraTaxa)
      callEvent(AppEventEnum.MovAtualProdAlterado, prod);

      showToast(
        'success',
        `Desconto de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
          ? `${model.vPorcentagem}%`
          : `R$ ${toDecimal(model.vValorFixo)}`} 
          foi atribuido ao Produto ${produto?.xProd}.`,
      )

    } catch (error: any) {
      showToast('error', error?.message)
    }
    fecharDialog()
  }, [adicionarAcrescimoDescontoItem, alterarProdutoCarrinho, callEvent, showToast])

  const adicionarAcrescimoProduto = useCallback(async (
    produto: MovSimplesProdutoModel,
    model: AdicionarDescontoAcrescimoFormModel,
    fecharDialog: () => void,
    naoAlteraTaxa: boolean = false,
  ) => {
    try {
      const prod = await adicionarAcrescimoDescontoItem(EnumAcrescimoDesconto.ACRESCIMO, produto, model);
      await alterarProdutoCarrinho(prod, naoAlteraTaxa)
      callEvent(AppEventEnum.MovAtualProdAlterado, prod);
      showToast(
        'success',
        `Acréscimo de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
          ? `${model.vPorcentagem}%`
          : `R$ ${toDecimal(model.vValorFixo)}`} 
        foi atribuido ao Produto ${produto?.xProd}.`)
    } catch (err: any) {
      showToast('error', err.message)
    }
    fecharDialog()
  }, [adicionarAcrescimoDescontoItem, alterarProdutoCarrinho, callEvent, showToast])

  return {
    reativarProduto,
    inativarProduto,
    alterarQtdProduto,
    adicionarDescontoProduto,
    adicionarAcrescimoProduto,
    alterarValorAcresDescQuantidadeProduto
  };
};
