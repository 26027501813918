import { Typography } from 'views/design-system';
import { CardCustumerProps } from './card-custumer-props';
import {
  DefaultCard,
  useDefaultCardStyles,
} from '../../../../../../../../components/cards/components/default-card';
import { StatusCadastroMock, TpCadastroMock } from 'data/mocks';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { memo } from 'react';
import { useCaptureCardNavigation } from 'views/components/cards/components/default-card/utils/capture-card-navigation';

const CardCustumer = ({ model, onClick, index }: CardCustumerProps) => {
  const classes = useDefaultCardStyles();
  const { cardRef } = useCaptureCardNavigation(index);

  const tpcadsatro = TpCadastroMock.filter((x) => x.Key === model.tpCadastro)[0]
    .Value;

  return (
    <>
      <DefaultCard
        onClick={() => {
          onClick(model);
        }}
        cardRef={cardRef}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid} style={{ minWidth: 130 }}>
            <Typography color="textPrimary" variant="caption">
              CPF/CNPJ {StatusCadastroMock.find(x => x.Key === model.status)?.Value}
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model?.cpfcnpj?.length > 0
                ? formatarCPFCNPJ(model.cpfcnpj)
                : 'Não Informado'}
            </Typography>
          </div>
          <div className={classes.celulaGridFull}>
            <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.nome}</strong>
            </Typography>
          </div>

          <div
            className={classes.celulaGrid}
            style={{ alignItems: 'flex-end', minWidth: 110 }}
          >
            <Typography color="textPrimary" variant="caption">
              Tipo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{tpcadsatro}</strong>
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};

export default memo(CardCustumer);
