import { EmpresaModel } from 'model/api/gestao/master';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export class IdentidadeNovoContratoProps {
    constructor(
        public nome: string = '',
        public email: string = '',
        public senha: string = '',
        public senhaConfirmacao: string = '',
        public apelido: string = '',
        public telefone: string = '',
        public empresa: EmpresaModel = new EmpresaModel(),
        public sistemaId: string = '',
        public planoId: string | null = null,
    ) { }
}

export function usePostIdentidadeNovoContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeNovoContrato = useCallback(
        (contratoProps: IdentidadeNovoContratoProps, chaveAtivacao: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Contrato`,
                method: 'POST',
                data: contratoProps,
                enviarTokenUsuario: false,
                headers: {
                    chaveAtivacao: chaveAtivacao
                },
                timeout: 60000,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeNovoContrato,
    };
}
